%selectSpecific {
	:global {
		.ant-select-selector {
			padding-top: 7px !important;
		}
		.ant-select-selection-placeholder {
			margin-top: -7px !important;
		}
	}
}
