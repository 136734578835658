@import 'Styles/_theme.scss';

.tableHeader {
	display: flex;
	width: 100%;
}
.tableHeaderContent {
	font-size: $font-size-xs;
	text-transform: uppercase;
	font-weight: 600;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 3px;
}
.tableHeaderTh {
	display: flex;
	align-items: center;
	padding: $table-cell-vertical-padd $heading-4;
	background-color: $body-background;
	border-bottom-color: $gray-2;
}

.virtualTableWrap {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.virtualRow {
	display: flex;
	transition: background-color 0.3s ease-in-out;

	&:last-child > div {
		border-bottom: none;
	}
}
.hasFooter {
	&:not(:last-child):hover {
		background-color: #fafafa;
	}
}
.noFooter {
	&:hover {
		background-color: #fafafa;
	}
}

.listWrap {
	height: 100%;
	border: 1px solid $gray-2;
	border-left: none;
	position: relative;
	h1,
	h3 {
		text-align: center;
	}
}

.listWrapEmpty {
	background-color: $body-background;
}

.virtualTd {
	padding: $table-cell-vertical-padd $gap-xs;
	border-bottom: 1px solid $gray-2;
}

.checkboxRow {
	border-bottom: 1px solid $gray-2;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0 0 8px;
}
.headerCheckbox {
	padding: 2px 0 0 5px;
}
.checkedRow {
	background-color: #e6fcff;
}
