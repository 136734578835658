@import './theme.scss';

%thead {
	thead > tr > th {
		font-size: $font-size-xs;
		text-transform: uppercase;
		font-weight: 600;
		padding: $table-cell-vertical-padd 14px;
		background-color: $body-background;
		border-bottom-color: $border-color;
	}
}
