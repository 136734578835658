@import 'Styles/_theme.scss';

.root {
	display: inline-block;
	line-height: 0;
	svg {
		display: block;
	}
}

.default {
	color: $default-color;
}

.md {
	font-size: $font-size-md;
}

.xl {
	font-size: 24px !important;
}
