@import 'Styles/_theme.scss';

.level {
	padding-right: $gap-sm;
}

.contentWrap {
	padding: 22px 0px 12px 12px;
	font-size: $font-size-sm;
	font-weight: 100;
}
