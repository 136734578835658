@import 'Styles/_theme.scss';

.root {
	height: $app-footer-height;
	padding: 0 $app-padd-horizontal;
	background: transparent;
	color: $gray-1;

	ul {
		list-style-type: none;
		margin-bottom: 0;
		padding: 0;
		display: flex;
		> li {
			padding: 0 10px;
			position: relative;

			&:not(:first-child):before {
				content: '\2022';
				color: $gray-1;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			> a {
				color: $gray-1 !important;
			}
		}
	}
}

.copyright {
	white-space: nowrap;
}
