@import 'Styles/_theme.scss';

.selectFile {
	width: 468px;
}

.suffix {
	color: $blue-2;
	font-size: $font-size-md;
	border-radius: 30px;
	padding: 3px 10px 1.5px;
	border: 1px solid $blue-2;
	cursor: pointer;
}

.disabledSelectedFile {
	pointer-events: none;

	input[type='text'] {
		color: $gray-3;
	}
	:global {
		.ant-input-suffix {
			div {
				border-color: $gray-3;
				color: $gray-3;
			}
		}
	}
}
