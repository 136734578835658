@import 'Styles/_theme.scss';

.navigation {
	margin-bottom: $gap-xs;
}

.usersDropdownWrapper {
	border-right: 1px solid $gray-2;
	padding-right: 10px;
}

.usersDropdown {
	width: 170px;
}
