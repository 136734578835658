@import 'Styles/_theme.scss';

.root {
	padding: $gap-xs 18px;
	box-shadow: none;
	line-height: 24px;
	box-shadow: 0px 3px 6px -4px rgba($black, 0.12),
		0px 6px 16px rgba($black, 0.08), 0px 9px 28px 8px rgba($black, 0.05);

	&.emptyMessage {
		:global(.ant-notification-notice-message) {
			display: none;
		}
	}

	&:global {
		&.ant-notification-notice-closable > .ant-notification-notice-content {
			padding-right: $gap-md;
		}
		&.ant-notification-notice-error {
			background-color: $error-bg;
			.ant-notification-notice-icon {
				color: $error-color;
			}
		}
		&.ant-notification-notice-success {
			background-color: $success-bg;
			.ant-notification-notice-icon {
				color: $success-color;
			}
		}
	}

	:global {
		.ant-notification-notice-content {
			line-height: 20px;
		}
		.ant-notification-notice-close {
			right: 16px;
			span[role='img'] {
				color: $default-color;
				font-size: 20px;
			}
		}
		.ant-notification-notice-icon {
			margin-left: 0;
		}
		.ant-notification-notice-with-icon {
			.ant-notification-notice-description {
				margin-left: 30px;
			}
		}
	}
}

:global {
	.ant-notification {
		z-index: $notification-z-index;
	}
}
