@import 'Styles/_theme.scss';

.moduleSettingsErrorTab {
	:global {
		.ant-tabs-tab {
			&:nth-child(3) {
				color: $error-color;
			}
		}
	}
}
