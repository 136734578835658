@import 'Styles/_theme.scss';

.root {
	$auth-box-padd-horizontal: 62px;

	.auth {
		margin: 0 auto;
		max-width: 430px;
		width: 100%;

		.logo {
			margin-bottom: 35px;
			text-align: center;
		}

		.box {
			margin-top: auto;
			margin-bottom: auto;
			max-width: 430px;
			width: 100%;
			background-color: $white;
			padding: 60px $auth-box-padd-horizontal;
			border: 1px solid $border-color;
			box-shadow: 1px 3px 15px rgba($black, 0.15);
			border-radius: 8px;
			h1 {
				margin-bottom: 20px;
			}
		}
	}
	> main,
	> footer {
		z-index: 1;
	}

	hr {
		width: calc(100% + #{$auth-box-padd-horizontal * 2});
		margin-left: -$auth-box-padd-horizontal;
	}

	button[type='submit'] {
		margin-top: 20px;
	}

	:global {
		.ant-alert {
			margin-bottom: 10px;
		}
	}
}

.firstLoginTitle {
	margin-bottom: 14px;
}

.validationQuide {
	font-size: $font-size-xs;
	line-height: 0.5;
}

.inputPhoneStyles {
	height: 110px;
	margin-bottom: 0 !important;
}

.wideBox {
	max-width: 700px !important;
}
