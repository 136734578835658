@import 'Styles/_mixins.scss';

.progressValue {
	font-size: 40px;
	font-weight: 600;

	@each $type-name, $type in $progress-types {
		&.#{$type-name} {
			color: map-get($type, 'mainColor');
		}
	}
}

.footer {
	margin: $content-vertical-padd 0;
}

.trendContent {
	padding-bottom: $content-vertical-padd;
}
