@import 'Styles/_theme.scss';

.root.vertical {
	display: flex;
	flex-direction: column;
	.item {
		margin-bottom: $gap;
	}
}

.item {
	margin: 0 $gap;
	font-size: $font-size-md;
	.value {
		font-weight: 600;
		margin-right: $gap/2;
		font-size: $font-size-lg;
	}
	.dot {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		margin-right: $gap;
	}
}
