@import 'Styles/_theme.scss';

.alignLeft {
	text-align: left;
}
.alignRight {
	text-align: right;
}
.alignCenter {
	text-align: center;
}
