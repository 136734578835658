.twoStepVerification {
	width: 50%;
}

.qrCodeWrap {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.input {
	margin-bottom: 0;
}

.h3 {
	font-weight: 600;
	text-align: center;
	margin-bottom: 14px;
}

.p {
	text-align: left;
	font-size: 10;
}

.form {
	margin-bottom: 14px;
}
