@font-face {
	font-family: 'Titillium';
	src: url(./Fonts/Titillium-Light.otf);
	font-weight: 300;
}
@font-face {
	font-family: 'Titillium';
	src: url(./Fonts/Titillium-Regular.otf);
	font-weight: 400;
}
@font-face {
	font-family: 'Titillium';
	src: url(./Fonts/Titillium-Semibold.otf);
	font-weight: 600;
}
@font-face {
	font-family: 'Titillium';
	src: url(./Fonts/Titillium-Bold.otf);
	font-weight: 700;
}
