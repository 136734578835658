@import 'Styles/_theme.scss';

@mixin button-variant($color, $background, $border: $background) {
	background-color: $background;
	border-color: $border;
	color: $color;

	&:hover,
	&:focus,
	&:active {
		background-color: darken($background, 7%);
		border-color: darken($border, 7%);
		color: $color;
	}

	&[disabled] {
		&,
		&:hover,
		&:focus,
		&:active {
			background-color: lighten($background, 15%);
			border-color: lighten($border, 15%);
			color: $color;
		}
	}
}

@mixin button-variant-ghost($color, $background, $border: $color) {
	background-color: $white !important;
	border-color: $border;
	color: $color;

	&:hover,
	&:focus,
	&:active {
		background-color: $background !important;
		border-color: darken($border, 7%);
		color: darken($color, 7%);
	}

	&[disabled] {
		&,
		&:hover,
		&:focus,
		&:active {
			background-color: $white !important;
			border-color: lighten($border, 15%);
			color: lighten($color, 15%);
		}
	}
}

.root {
	> span[role='img'] {
		font-size: 20px;
		& + span {
			margin-left: $gap;
		}
	}
	&:global(.ant-btn) {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: $gap $gap-xs;

		&.thin {
			padding: 0;
			height: auto;
		}

		&:global(.ant-btn-text).link {
			font-weight: 300;
			&,
			&:hover,
			&:focus,
			&:active {
				color: $link-color;
			}

			&:hover,
			&:focus,
			&:active {
				> span {
					text-decoration: underline;
				}
			}
		}

		&.link,
		&:global(.ant-btn-text) {
			font-size: inherit;
			border-radius: 0;
		}
	}
	&:global {
		&.ant-btn {
			line-height: 1.3;
			font-size: $font-size-md;
			font-weight: 600;

			&.ant-btn-text {
				padding-left: 0;
				padding-right: 0;
				font-weight: inherit;
				border: 0;

				&,
				&:hover,
				&:focus,
				&:active {
					color: inherit;
					background-color: transparent;
				}
			}
		}

		&.ant-btn-primary {
			&:not(.ant-btn-background-ghost) {
				@include button-variant($white, $main-color);
			}
			&.ant-btn-background-ghost {
				@include button-variant-ghost($main-color, $orange-1);
			}
		}

		&.ant-btn-default {
			&:not(.ant-btn-background-ghost) {
				@include button-variant($white, $default-color);
			}
			&.ant-btn-background-ghost {
				@include button-variant-ghost(
					$default-color,
					$default-bg,
					$default-color
				);
			}
		}

		&.ant-btn-text {
			color: inherit;
		}

		&.ant-btn-round {
			&.ant-btn-sm {
				height: 32px;
				padding: 6px;
				border-radius: 8px;
			}
		}
	}
}

.fullWidth {
	width: 100%;
}

.thinVertical {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.text {
	background-color: transparent !important;
	border-color: transparent !important;

	&:global {
		&.ant-btn-default {
			color: $default-color !important;
		}
	}
}
