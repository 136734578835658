@import 'Styles/_theme.scss';

.root {
	height: 100%;
	:global {
		.ant-tabs-content,
		.ant-tabs-tabpane,
		.ant-tabs-content-holder {
			height: 100%;
		}
		.ant-tabs-content-holder {
			background-color: $white;
			border: 1px solid $border-color;
			border-top: 0;
			overflow-y: auto;
		}
		.ant-tabs-nav {
			margin-bottom: 0;
			&:before {
				border-bottom: 1px solid $border-color;
			}
		}
		.ant-tabs-tab {
			font-size: $font-size-md;
			padding: $gap 28px;
		}
	}
}
