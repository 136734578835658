$white: #fff;
$black: #000;

$blue-1: #004c6c;
$blue-2: #0093d0;
$blue-3: #e6f2fa;
$blue-4: #1890ff;

$gray-1: #7c8180;
$gray-2: #e1e2e2;
$gray-3: #abaead;
$gray-4: #eff1f1;

$orange-1: #fce3c6;
$orange-2: #f49e92;

$main-color: #f7921e;
$primary-bg: $blue-3;
$primary-color: $blue-2;
$error-color: #ec5e49;
$error-bg: #fbdfdb;
$success-color: #6fcf97;
$success-bg: #e2f5ea;
$default-color: $gray-1;
$default-bg: $gray-4;
$warning-bg: $orange-1;
$warning-color: $orange-2;

$link-color: $blue-2;
$text-color: #303c42;
$border-color: $gray-2;
$body-background: #f8fafc;

$grid-gutter-base: 8px;
$grid-gutter: 32px;

$gap: $grid-gutter-base;
$gap-xs: 16px;
$gap-sm: 24px;
$gap-md: 32px;

$content-horizontal-padd: 60px;
$content-vertical-padd: $gap-sm;

$app-header-height: 48px;
$app-footer-height: 55px;
$app-padd-horizontal: 40px;

$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;

$heading-massive: 48px;

$heading-1: 24px;
$heading-2: 20px;
$heading-3: 18px;
$heading-4: 14px;

// Components
$border-radius: 4px;

$button-sm: 32px;

$dropdown-min-width: 150px;
$dropdown-menu-item-color: $text-color;
$dropdown-menu-item-hover-bg: #e6f2fa;

$table-cell-vertical-padd: 12px;
$tag-border-radius: 6px;

$modal-z-index: 1051;
$dropdown-z-index: $modal-z-index + 1;
$notification-z-index: $modal-z-index + 1;

$input-vertical-gap: $gap;
$input-placeholder-color: $gray-3;
$input-icon-color: $default-color;
$input-clear-icon-size: $font-size-lg;
$input-height-base: 40px;

$form-item-margin-bottom: $gap-sm;

$picker-prefix-gap: 50px;
$picker-prefix-after-gap: $gap;

// Progress
$progress-types: (
	happy: (
		mainColor: $success-color,
		iconBgColor: $success-bg
	),
	normal: (
		mainColor: $main-color,
		iconBgColor: $default-bg
	),
	sad: (
		mainColor: $error-color,
		iconBgColor: $error-bg
	)
);
$statistic-types: (
	default: (
		mainColor: $default-color,
		iconBgColor: $gray-2
	),
	primary: (
		mainColor: $primary-color,
		iconBgColor: $primary-bg
	)
);

$statistics-sizes: (
	md: (
		size: 44px,
		countFontSize: $heading-1,
		textFontSize: $font-size-sm
	),
	lg: (
		size: 67px,
		countFontSize: $heading-massive,
		textFontSize: $font-size-lg
	)
);
