@import 'Styles/_theme.scss';

.tabsContentInner {
	margin: 0 (-$content-horizontal-padd);
	:global {
		.ant-tabs-content-holder {
			border: 0;
		}
	}
}
