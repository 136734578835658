@import 'Styles/_theme.scss';

.modal {
	&.root {
		background-color: $body-background;

		.search {
			margin: $gap-xs;
		}

		.body {
			max-height: 500px;
			border-top: 1px solid $border-color;

			:global {
				.ant-tree {
					.ant-tree-list {
						.ant-tree-treenode {
							padding-top: 4px !important;
							padding-bottom: 4px !important;
						}
						.ant-tree-treenode-checkbox-checked,
						.ant-tree-treenode-selected {
							.ant-tree-title span[role='img'] {
								position: absolute;
								top: 50%;
								right: 0;
								transform: translateY(-50%);
							}
						}
						.ant-tree-node-content-wrapper {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.sidebar {
	:global {
		.ant-tree-list {
			.ant-tree-treenode {
				border-left: 2px solid transparent;
			}
			.ant-tree-treenode-selected {
				border-left-color: $blue-4;
			}
		}
	}

	.search {
		margin-top: $gap;
	}
}
