@import 'Styles/_theme.scss';

.root {
	color: $text-color;
}

.default {
	color: $default-color;
}

.success {
	color: $success-color;
}

.warning {
	color: $warning-color;
}

.error {
	color: $error-color;
}

.primary {
	color: $primary-color;
}

.lg {
	font-size: $font-size-lg;
}
