@import 'Styles/theme';

.root {
	text-align: center;
}

.title {
	text-align: center;
	position: relative;
	line-height: 1.3;
}

.subTitle {
	display: block;
	font-size: $font-size-sm;
	margin-top: $gap-xs;
	margin-bottom: $gap-xs;
	line-height: 1.3;
}
