@import 'Styles/_theme.scss';

.root {
	box-shadow: 0px 1px 3px rgba($black, 0.15);
	border-radius: 2px;
	border: 1px solid $border-color;
	width: 100%;
	display: flex;
	flex-direction: column;

	:global {
		.ant-card-head {
			margin: $gap-sm $gap-sm 0;
			min-height: 45px;
			padding: 0;
		}
		.ant-card-head-title {
			padding: 0;
		}
		.ant-card-extra {
			padding: 0;
		}
		.ant-card-body {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding: 0;
		}
	}

	&:global {
		&.ant-card-hoverable:hover {
			border-color: $blue-2;
			box-shadow: none;
		}
	}
}

.content {
	flex: 1;
	padding: $gap-xs $content-vertical-padd;
}

.footer {
	border-top: 1px solid $default-bg;
	margin: 0 $content-vertical-padd;
}

.loading {
	visibility: hidden;
	* {
		transition: none !important;
	}
	.spin {
		visibility: visible;
	}
}
