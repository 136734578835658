@import 'Styles/_theme.scss';
.levelItem {
	flex: 1;
	margin-right: $gap-xs;
	height: 90px;
}

.levelItemName {
	flex-grow: 2;
}

.levelRadioButtons {
	:global {
		.ant-row.ant-form-item {
			.ant-col.ant-form-item-control {
				margin-top: 10px;
			}
		}
	}
}
