@import 'Styles/_theme.scss';

.separator {
	padding: 0 $gap-xs #{$form-item-margin-bottom + $gap};
	text-transform: uppercase;
}

.propertiesCheckbox {
	padding-left: $gap-xs;
	padding-bottom: $gap;
}

.inputSuffixAside {
	:global {
		.ant-input-suffix {
			position: absolute;
			right: 0;
			top: 0;
			transform: translateX(100%);
			height: 100%;
		}
	}
}
