@import 'Styles/_theme.scss';

.dropdown {
	:global {
		.ant-select-item-option-content {
			white-space: initial;
			overflow: visible;
		}
	}
}
