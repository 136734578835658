@import 'Styles/_theme.scss';

.checkbox {
	margin-bottom: 4px;
}

.checkboxGroup {
	margin-top: $gap;
	margin-left: $gap-sm;
}
