@import 'Styles/_theme.scss';

@mixin getStatisticItemType($type-name, $type) {
	&.#{$type-name} {
		$mainColor: map-get($type, 'mainColor');

		color: $mainColor;

		.iconHolder {
			background-color: map-get($type, 'iconBgColor');
		}

		&.inheritColor {
			.count {
				color: $mainColor;
			}
		}
	}
}

.root {
	$size-svg: 24px;
	$lg-size-svg: 32px;

	@each $type-name, $type in $progress-types {
		@include getStatisticItemType($type-name, $type);
	}

	@each $type-name, $type in $statistic-types {
		@include getStatisticItemType($type-name, $type);
	}

	@each $size-name, $size in $statistics-sizes {
		&.#{$size-name} {
			.iconHolder {
				width: map-get($size, 'size');
				height: map-get($size, 'size');

				@if $size-name == lg {
					svg {
						width: $lg-size-svg;
						height: $lg-size-svg;
					}
				} @else {
					svg {
						width: $size-svg;
						height: $size-svg;
					}
				}
			}
			.textHolder {
				font-size: map-get($size, 'textFontSize');
				color: $default-color;
			}
			.count {
				font-size: map-get($size, 'countFontSize');
			}
		}
	}

	font-weight: 100;
}

.vertical {
	flex-direction: column;
	text-align: center;

	.textHolder {
		margin-left: 0;
		margin-top: $gap-xs;
	}
}

.count {
	font-weight: 600;
	line-height: 1;
}

.textHolder {
	margin-left: 20px;
	line-height: 1.42;
}

.iconHolder {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}
