@import 'Styles/_theme.scss';

.root {
	background-color: $body-background;
	position: relative;
	&,
	> main {
		overflow-y: auto;
		height: 100%;
	}

	> header {
		height: $app-header-height;
		background-color: $blue-1;
		padding: 0 $app-padd-horizontal;

		&.themeLight {
			background-color: transparent;
		}
	}

	> main {
		overflow-y: auto;
		padding: $content-vertical-padd $app-padd-horizontal 0;
	}

	> footer {
		height: $app-footer-height;
		padding: 0 $app-padd-horizontal;
		background: transparent;
		color: $gray-1;

		ul {
			list-style-type: none;
			margin-bottom: 0;
			padding: 0;
			display: flex;
			> li {
				padding: 0 10px;
				position: relative;

				&:not(:first-child):before {
					content: '\2022';
					color: $gray-1;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				> a {
					color: $gray-1 !important;
				}
			}
		}
	}
}
