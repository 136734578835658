@import 'Styles/_theme.scss';
@import 'Styles/_mixins.scss';

.infosecCompositeIndex {
	margin-left: $content-horizontal-padd;
}

.filter {
	margin-bottom: 0;
	min-width: 200px;
}

.graph {
	width: 100%;
}

.line {
	min-height: 58px;
	padding-top: $gap;
	padding-bottom: $gap;
}

.title {
	width: 200px;
	text-align: right;
	line-height: 1.2;
	color: $text-color;
	font-size: $font-size-md;
}

.graphPercentHolder {
	width: 150px;
}

.graphPercentValue {
	font-size: $heading-1;
	margin-left: $gap;
	font-weight: 600;
	margin-right: $gap-xs;
}
