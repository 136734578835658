@import 'Styles/_theme.scss';

.root {
	> .root {
		flex: 1;
		overflow-y: auto;
	}

	*:not(.root) > & {
		height: 100%;
	}
}

.fullHeight {
	height: 100%;
}

.header {
	margin-bottom: $gap-xs;

	&.alignedLeft {
		padding-right: $gap-xs;
	}
	&.alignedRight {
		padding-left: $gap-xs;
	}
}

.heading {
	margin-bottom: $gap-sm;
	.headingPrefix {
		align-self: center;
		display: inline-flex;
	}
	.headingContent {
		flex-grow: 1;
	}
	.headingPrefix + .headingContent {
		border-left: 1px solid $border-color;
		padding-left: $content-vertical-padd;
		margin-left: $content-vertical-padd;
	}
	.headingSearchField {
		margin-left: -$gap-sm;
		padding: 0 $gap-xs;
		flex-grow: 1;
		flex-shrink: 0;
	}
}

.body {
	height: 100%;
	overflow-y: auto;
	max-width: 876px;
	width: 100%;
	background-color: $white;
	box-shadow: inset 0px -1px 0px #f0f0f0;
	border: 1px solid $border-color;
	margin: 0 auto;
}

.content {
	padding: $content-vertical-padd $content-horizontal-padd;
	overflow-y: auto;
}

.footer {
	padding: $content-vertical-padd;
	text-align: center;
	border-top: 1px solid $border-color;
	margin-top: auto;
}

.list {
	counter-reset: section;

	> .listItem {
		position: relative;
		padding-bottom: $content-vertical-padd;
		min-height: 100%;

		&:not(:only-child):not(:first-child) {
			margin-top: $content-vertical-padd;

			&:before {
				content: '';
				position: absolute;
				top: -$content-vertical-padd;
				height: 1px;
				width: 100%;
				background-color: $border-color;
				.content & {
					left: -$content-horizontal-padd;
					width: calc(100% + #{$content-horizontal-padd * 2});
				}
			}
		}

		> h1 {
			margin-bottom: $content-vertical-padd;
			&:before {
				counter-increment: section;
				content: counter(section) '. ';
			}
		}
	}
}

.bodyFullWidth {
	max-width: 100%;
}

.listItemBody {
	display: flex;
	min-height: inherit;

	&.stacked {
		flex-direction: column;
	}

	&:not(.stacked) {
		&.use80 {
			> .section {
				flex: 0 0 80%;
				max-width: 80%;
			}
		}
		> .section {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 $content-horizontal-padd;
			&:first-child {
				padding-left: 0;
			}
			&:not(:only-child):last-child {
				padding-right: 0;
			}

			&:before {
				background-image: linear-gradient(
					to bottom,
					$border-color 0%,
					$border-color 50%,
					transparent 50%
				);
				background-size: 2px 15px;
				background-repeat: repeat-y;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 2px;
			}
		}
	}

	&.stacked {
		> .section {
			padding: $content-vertical-padd 0;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
			&:before {
				background-image: linear-gradient(
					to right,
					$border-color 0%,
					$border-color 50%,
					transparent 50%
				);
				background-size: 15px 2px;
				background-repeat: repeat-x;
				position: absolute;
				top: 0;
				left: 0;
				height: 2px;
				width: 100%;
			}
		}
	}
}

.section {
	position: relative;

	h2 {
		margin-bottom: $content-vertical-padd;
	}

	&:not(:only-child):not(:first-child):before {
		content: '';
	}
}

.mountainView {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	img {
		display: block;
		max-width: 100%;
	}
}
