@import 'Styles/_theme.scss';
@import 'Styles/_mixins.scss';
@import 'Components/LanguageMenuItem/LanguageMenuItem.module';

.headerActionButtonsWrapper {
	margin-left: auto;
}

.headerImportButton {
	margin-right: $grid-gutter-base;
}

.languageSelect {
	@extend %selectSpecific;
}

.form {
	height: 100%;
	overflow-y: auto;
}

.footer {
	padding-top: 8px;
}

.addUsersSelect {
	margin-bottom: 0 !important;
	:global {
		.ant-form-item-explain-error {
			min-height: 0;
		}
	}
}

.addMultipleUsersTable {
	background-color: #fff;
	:global {
		.ant-table-header > table > thead > tr > th {
			padding: 0px 0px 8px 14px;
			& > div > span > .ant-btn {
				padding-right: 4px;
			}
		}
		.ant-table-body > table > tbody > tr > td {
			padding: 4px 4px;
		}
	}
}

.paginationRow {
	padding: 4px 0;
}
