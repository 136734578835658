@import 'Styles/_theme.scss';

@mixin checkbox-variant($color, $background, $border: $background) {
	background-color: $background;
	border-color: $border !important;
	&:after {
		border-color: $color;
	}
}

.root {
	:global {
		.ant-checkbox-inner {
			border-radius: 2px;
		}
		.ant-checkbox + span {
			padding: 0 #{$gap/2};
		}
		input[disabled] + .ant-checkbox-inner {
			@include checkbox-variant($gray-3, $default-bg, $border-color);
		}
	}
}

.vertical {
	&:global(.ant-checkbox-group) {
		display: flex;
		flex-direction: column;

		> label {
			margin-bottom: $form-item-margin-bottom;
		}
	}
}
