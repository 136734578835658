@import 'Styles/_theme.scss';

.before {
	padding-right: $gap;
}

.after {
	padding-left: $gap;
}

.progress {
	display: block;
	line-height: 0;
	flex: 1;

	@each $type-name, $type in $progress-types {
		&.#{$type-name} {
			:global {
				.ant-progress-bg {
					background-color: map-get($type, 'mainColor');
				}
			}
		}
	}

	:global {
		.ant-progress-inner,
		.ant-progress-bg {
			border-radius: 0;
		}

		.ant-progress-inner {
			background-color: $default-bg;
		}

		.ant-progress-bg {
			background: $primary-color;
		}
	}
}
