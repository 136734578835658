@import 'Styles/_theme.scss';

$modal-vertical-padd: 30px;
$modal-horizontal-padd: 40px;

.root {
	top: 130px;
	:global {
		.ant-modal-header {
			padding: $modal-vertical-padd $modal-horizontal-padd 0;
			border-bottom: 0;
		}
		.ant-modal-title {
			font-size: $heading-2;
			text-align: center;
		}
		.ant-modal-body {
			padding: $modal-vertical-padd $modal-horizontal-padd;
		}
		.ant-modal-content {
			border-radius: 8px;
		}
		.ant-modal-footer {
			text-align: center;
			padding: $gap-xs $modal-horizontal-padd;
		}
		.ant-modal-close {
			margin-top: $gap;
			font-size: 20px;
		}
	}
}

:global {
	.ant-modal-wrap {
		z-index: $modal-z-index;
	}
}
