@import 'Styles/_theme.scss';

.overlay {
	min-width: 150px !important;
	max-width: 150px;
}

.menuItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.menuItemIcon {
	margin: 0 !important;
}

.menuItemActive {
	color: $primary-color;
}
.dropDownContent {
	font-size: $heading-1;
	color: $text-color;
}
