@import 'Styles/_theme.scss';

.root {
	&:global(.ant-form-item) {
		flex-direction: column;
	}
	&:global(.ant-form-item-with-help) {
		margin-bottom: $form-item-margin-bottom;
	}

	:global(.ant-form-horizontal) & {
		flex-direction: row;
		label {
			padding-bottom: 0;
		}
	}

	> :global(.ant-form-item-label) {
		text-align: left;
		> label {
			height: 25px;
		}
	}

	:global {
		.ant-form-item-control-input {
			min-height: auto;
		}
		.ant-checkbox-wrapper {
			padding-bottom: 0;
		}
		.ant-form-item-required {
			&:after {
				content: '*' !important;
				margin: 0px !important;
			}
			&:before {
				content: '' !important;
				margin: 0px !important;
			}
		}
	}
}

.horizontal {
	display: flex;
	flex-direction: row !important;
	align-items: center;
}

.noMarginBottom {
	margin-bottom: 0;
}

.fullWidth {
	width: 100%;
}

.labelAlignRight {
	:global {
		.ant-form-item-label {
			text-align: right !important;
			margin-right: $gap;
		}
	}
}

.text {
	:global {
		.ant-form-item-control-input-content {
			height: $input-height-base;
			display: inline-flex;
			align-items: center;
		}
	}
}
