@import 'Styles/_theme.scss';

.root {
	&:global(.ant-tree) {
		background-color: transparent;
	}
	:global {
		.ant-tree-list {
			.ant-tree-list-holder-inner > .ant-tree-treenode {
				width: 100%;
			}
			.ant-tree-treenode {
				padding: 10px 12px;
				align-items: center;
			}

			.ant-tree-treenode-disabled {
				.ant-tree-node-content-wrapper {
					color: inherit;
				}

				.ant-tree-title {
					position: relative;
					display: inline-block;

					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($body-background, 0.6);
					}
				}
			}
			.ant-tree-treenode-checkbox-checked,
			.ant-tree-treenode-selected {
				background-color: $dropdown-menu-item-hover-bg;
			}

			// prevent background-color shrinking when in motion during expand/collapse
			.ant-tree-treenode-motion {
				width: 100%;
			}

			.ant-tree-switcher {
				display: inline-flex;
			}

			.ant-tree-switcher-icon {
				display: flex;
				align-items: center;
			}

			.ant-tree-checkbox {
				margin-top: 0 !important;
			}

			.ant-tree-switcher,
			.ant-tree-node-content-wrapper {
				line-height: normal;
				min-height: 0;
				padding-top: 3px;
			}

			.ant-tree-node-content-wrapper.ant-tree-node-selected,
			.ant-tree-node-content-wrapper:hover {
				background-color: transparent;
			}
		}
	}
}

.flat {
	:global {
		.ant-tree-switcher {
			display: none !important;
		}
	}
}

.hasRoot {
	:global {
		.ant-tree-list-holder-inner
			> .ant-tree-treenode-leaf-last:first-child
			> .ant-tree-switcher {
			display: none;
		}

		.ant-tree-indent > .ant-tree-indent-unit:first-child {
			width: 0;
		}
	}
}
