@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-input-prefix,
		.ant-input-suffix {
			color: $default-color;
		}
		.ant-input-prefix {
			font-size: 24px;
		}
		.ant-input-suffix {
			font-size: 22px;
		}
	}

	&:global {
		&.ant-input-search {
			.ant-input-group-addon {
				display: none;
			}
		}

		> .ant-input-wrapper > .ant-input-affix-wrapper {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	:global(.ant-form-item-has-error) & {
		border-color: $error-color !important;
	}
}

.hidden {
	visibility: hidden;
}

.fullWidth {
	width: 100%;
}
