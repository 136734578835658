@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-spin-dot {
			vertical-align: middle;
		}
	}
}

.overlay {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

.xs {
	margin: $gap;
}
