@import 'Styles/theme';

.root {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	min-height: 40px;
	margin: 10px 0;
	justify-content: center;

	:global {
		.ant-pagination-options {
			position: absolute;
			left: 0;
			margin: 0;
		}

		.ant-pagination-total-text {
			position: absolute;
			left: 125px;
			top: 6px;
		}

		//	By default in ant design button size and base input size are equal and by default equal to 32px
		//	App is configured with base input size equal to 40px, and pagination should be 32px which is the same for small size button
		.ant-select-single .ant-select-selector {
			height: $button-sm;
		}

		.ant-select-single .ant-select-selector .ant-select-selection-item {
			line-height: $button-sm - 2px;
		}
	}
}

.totalCount {
	padding: 0;
}
