@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-table-container .ant-table-body {
			border-left: 0;
		}
		.ant-table-footer {
			padding: $gap $gap-xs;
		}
	}
}

.sidebar {
	height: 100%;
	width: 100%;
	max-width: 280px;
	border-right: 2px solid $border-color;
	position: relative;
}

.addMultipleGroupsMenuTitle {
	margin-bottom: $table-cell-vertical-padd;
}

.searchDropdown {
	width: 400px !important;
	:global {
		.rc-virtual-list-holder {
			overflow-y: scroll !important;
		}
	}
}
