@import 'Styles/_theme.scss';

.root {
	position: relative;
	&:before {
		content: '';
		background-image: linear-gradient(
			to right,
			$border-color 0%,
			$border-color 50%,
			transparent 50%
		);
		background-size: 15px 2px;
		background-repeat: repeat-x;
		position: absolute;
		top: 0;
		left: 0;
		height: 2px;
		width: 100%;
	}
}
