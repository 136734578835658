@import 'Styles/_theme.scss';

.table {
	overflow-y: hidden;
	max-height: 90%;
}

.tabs {
	:global {
		.ant-tabs-content-holder {
			background-color: $body-background;
			border: none;
		}
	}
}

.paginationWrap {
	background-color: $body-background;
}
