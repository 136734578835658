@import 'Styles/_theme.scss';

.content {
	margin: 0 -#{$app-padd-horizontal};
	:global {
		.ant-tabs-nav-list {
			margin-left: $app-padd-horizontal;
		}
		.ant-tabs-content-holder {
			background-color: transparent;
			border: none;
			padding: $gap-sm $app-padd-horizontal 0;
		}
	}
}
