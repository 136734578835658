@import 'Styles/_theme.scss';

@mixin alert-variant($background, $border) {
	background-color: $background;
	border-color: $background;

	> .anticon:first-child {
		color: $border;
	}
}

.root {
	border-radius: 6px;
	padding: 10px 18px;

	:global {
		.ant-alert-description {
			font-weight: 400;
		}
	}

	&:global {
		&.ant-alert-with-description .ant-alert-message {
			margin-bottom: 0;
		}

		&.ant-alert-error {
			@include alert-variant($error-bg, $error-color);
		}
	}
}
