@import 'Styles/_theme.scss';

.levelBlockWrap {
	margin-top: 5px;
	display: flex;
	justify-content: space-between;
}

.downloadButton {
	margin-top: auto;
}

.uploadContainer {
	margin-top: 29px;
}

.title {
	margin-bottom: $gap-xs;
}

.uploadFileWrap {
	margin-bottom: $gap-sm;
}
