@import 'Styles/_theme.scss';

.breadcrumbs {
	padding: 12px 0 $gap-sm;
	border-bottom: 1px solid $border-color;
	margin-bottom: $gap-sm;
	.breadcrumbsTitle {
		font-size: $font-size-xs;
		font-weight: 600;
		text-transform: uppercase;
	}
	.breadcrumbsList {
		> span:not(:last-child)::after {
			content: '>';
			display: inline-block;
			padding: 0 $gap;
		}
		> span:last-child {
			font-weight: 600;
		}
	}
}
