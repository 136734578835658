@import 'Styles/_theme.scss';

.status {
	padding-left: 5px;
}

.disabled {
	color: $gray-3;
}
.setupPending {
	color: $error-color;
}

.setup {
	color: $success-color;
}

.displayNone {
	display: none;
}
