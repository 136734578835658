@import 'Styles/_theme.scss';

.root {
	height: inherit;
	display: flex;
	align-items: center;
	width: 100%;
}

.input {
	max-width: 680px;
	display: inline-block;

	:global {
		// create space for icon
		.ant-input-affix-wrapper {
			padding-top: 7px;
			padding-bottom: 7px;
		}
	}
}

.inputSearchIcon {
	font-size: 24px;
	color: $default-color;
	margin-left: calc(#{$gap-xs} + 1px); // compensate border width
}
