@import 'Styles/_theme.scss';

.root {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;

	> div,
	.tooltipOverlay {
		position: static !important;
	}
}

.tooltipOverlay {
	top: 0 !important;
	left: 0 !important;

	:global(.ant-tooltip-arrow) {
		display: none;
	}
	:global {
		.ant-tooltip-inner {
			color: $default-color;
			font-size: $font-size-sm;
			font-weight: 100;
			background-color: $white;
			padding: $gap-xs $gap-xs $gap;
			overflow-y: auto;
		}
	}
}

.tooltipValue {
	white-space: nowrap;
	.count {
		font-size: $heading-1;
		font-weight: 600;
	}

	> .tooltipValueItem {
		display: flex;

		&:only-child {
			flex-direction: column;
		}

		&:not(:only-child) {
			align-items: center;
			.count {
				margin-left: $gap;
			}
			&:not(:last-child) {
				border-bottom: 1px solid $border-color;
			}
		}
	}
}
