@import 'Styles/_theme.scss';

.rulesList {
	margin-bottom: $gap-md;
}

.title {
	margin-bottom: $gap-xs;
}

.downloadXLSXLink {
	margin-top: auto;
	margin-bottom: 12px;
}

.downloadXLSXLinkWithTooltip {
	margin-top: auto;
}

.groupField {
	width: 302px;
	margin-right: 29px;
}

.userGuide {
	margin-top: $gap-sm;
	> p {
		margin-bottom: 0;
	}
}
