@import 'Styles/_theme.scss';

.pathSummary {
	overflow-y: auto;
	max-height: 137px;

	.pathSummaryList {
		overflow-y: auto;
	}

	.progress {
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: $content-vertical-padd;
		}
	}

	.name {
		width: 150px;
		text-align: right;
	}

	.value {
		display: block;
		width: 100px;
	}
}

.courseActivityStats {
	padding-top: $gap-xs;
	padding-bottom: $gap-xs;
}
