@import 'Components/LanguageMenuItem/LanguageMenuItem.module.scss';

.form {
	width: 100%;
	padding: 24px 14px 0;
	margin-bottom: 19px;
	background-color: #f0f1f1;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.selectLong {
	width: 20%;
}
.select {
	width: 14%;
}

.selectShort {
	width: 8%;
	@extend %selectSpecific;
}
