@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-table-container .ant-table-body {
			border-left: 0;
		}
	}
}

.sidebar {
	height: 100%;
	width: 100%;
	max-width: 280px;
	border-right: 2px solid $border-color;
	position: relative;

	h1 {
		margin-bottom: $gap;
	}
}

.tableFooter {
	padding: $gap $gap-xs;
}
