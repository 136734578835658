@import 'Styles/_mixins.scss';

.dynamicFormContent {
	height: 145px;
}

.assistance {
	margin-top: $gap-xs;
}

.inputEmailWrap {
	max-width: 45%;
	height: 92px;
}

.ButtonResendValidationEmail {
	margin-top: auto;
}

.form {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.buttonEmailWrap {
	margin-top: auto;
}
