@import 'Styles/_theme.scss';

.reports {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	gap: $gap-xs;
	margin: $gap 0 $gap-md;
}

.card {
	border-radius: $border-radius;

	.icon {
		background-color: $primary-bg;
		border-radius: 50%;
		width: 44px;
		height: 44px;
		margin-right: $gap-xs;
	}

	.icon,
	h3 {
		color: $primary-color;
	}
}

.cardContent {
	padding: $gap-sm $gap-xs;
}
