@import 'Styles/_theme.scss';

@mixin ghost($color) {
	color: $color;
	border-color: $color;
}

.root {
	border-color: transparent;
	font-size: $font-size-sm;
	padding: 1px $gap 0;
	line-height: 1.25;
}

.success {
	background-color: $success-bg;
}

.default {
	background-color: $border-color;
}

.error {
	background-color: $error-bg;
}

.warning {
	background-color: $warning-bg;
}

.primary {
	background-color: $primary-bg;
}

.ghost {
	background-color: $white;
	&.error {
		@include ghost($error-color);
	}
}
