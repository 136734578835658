@import 'Styles/_theme.scss';

.hierarchyLayout {
	.layoutBody {
		*:not(.root) > & {
			height: initial;
		}
	}
}

.radioGroup {
	& > label {
		display: block;
	}
}

.contentTitle {
	margin-bottom: 23px;
}

.baseContent {
	margin-bottom: 44px;
}
.hierarchyLevel {
	margin-bottom: 23px;
}
.marginBottomNull {
	margin-bottom: 0;
}

.layout {
	width: 100%;
}

.layoutBody {
	border: none;
	overflow-y: hidden;
}

.centeredTd {
	display: flex;
	justify-content: center;
	align-items: center;
}

.alignedStartTd {
	display: flex;
	align-items: center;
}

.levelTitle {
	font-size: $heading-3;
}
