@import 'Styles/_theme.scss';

.input {
	width: 100%;
	font-size: 14px;
	flex: 1;
	appearance: none;
	border: 1px solid rgb(225, 226, 226);
	padding: 8px 40px 8px 16px;
	border-radius: $border-radius;
	&:focus {
		border-color: #23acde !important;
		border-right-width: 1px !important;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(0, 147, 208, 0.2);
	}
}
.hasError {
	border-color: $error-color !important;
	transition: border-color 300ms ease-in-out;
	&:focus {
		border-color: $error-color !important;
		border-right-width: 1px !important;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(236, 94, 73, 0.2);
	}
}

.formItem {
	display: flex !important;
	position: relative;
}

.iconWrap {
	cursor: pointer;
	font-size: 0px;
	position: absolute;
	top: 0;
	right: 0;
	padding: 9px 17px 5px 5px;
}

.icon {
	color: rgba(0, 0, 0, 0.45);
	transition: color 300ms ease-in-out;
	font-size: 22px;
	& :hover {
		color: rgb(0, 0, 0);
	}
}
