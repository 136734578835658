@import 'Styles/_theme.scss';

.headerRootRight {
	display: flex;
	align-items: center;
	font-size: $font-size-sm;
}

.property {
	font-size: 14px;
}
.radioGroup {
	margin-left: 10px;
	margin-bottom: 0 !important;
}
.importGroups {
	font-size: 14px;
	padding: 0 26px;
}
.group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 16px;
}
.itemRootContainer {
	background-color: $body-background;
	padding: 24px;
	margin-bottom: 24px;
}
.controlContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.formItem {
	flex-grow: 1;
	margin-right: 16px;
}
.revertIcon {
	transform: rotateZ(90deg);
}
.button {
	align-self: flex-end;
	margin-bottom: 5px;
}
