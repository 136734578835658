@import 'Styles/_theme.scss';

.root {
	margin: 18px 12px;
	.title {
		font-weight: 600;
		text-transform: uppercase;
		font-size: $font-size-xs;
		display: inline-block;
		margin-right: $gap;
	}
}
