@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-radio-button-wrapper {
			box-shadow: none !important;
			height: $button-sm;
			line-height: #{$button-sm - 1};
		}
	}
}

.vertical {
	display: flex;
	flex-direction: column;

	> label:not(:last-child) {
		margin-bottom: $form-item-margin-bottom;
	}
}
