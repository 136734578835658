@import 'Styles/_theme.scss';

.root {
	width: 100%;
	padding-left: $picker-prefix-gap;
	padding-right: $gap-sm;

	:global {
		.ant-picker-suffix {
			position: absolute;
			left: -1px;
			margin-left: -#{$picker-prefix-gap - $picker-prefix-after-gap * 2};
			color: $input-icon-color;
		}

		.ant-picker-clear {
			right: -$gap-xs;
			font-size: $input-clear-icon-size;
			color: $input-icon-color;
		}
	}
}

.range {
	width: 100%;
	padding-left: $picker-prefix-gap;
	padding-right: $gap-sm;

	:global {
		.ant-picker-range-separator {
			color: $input-placeholder-color;
		}

		.ant-picker-suffix {
			position: absolute;
			left: $gap-xs;
			margin-left: 0;
			color: $input-icon-color;
		}

		.ant-picker-active-bar {
			margin-left: $picker-prefix-gap - $picker-prefix-after-gap;
		}

		.ant-picker-clear {
			right: $gap;
			font-size: $input-clear-icon-size;
			color: $input-icon-color;
		}
	}
}
