@import 'Styles/_theme.scss';

.root {
	cursor: pointer;
	padding: 0;

	display: inline-flex;
	align-items: center;

	> div {
		display: inline-flex;
	}

	> span[role='img'] {
		margin-left: 5px;
	}
}

.menu {
	:global {
		.ant-dropdown-menu {
			margin-top: 8px;
			padding: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			> .ant-dropdown-menu-item {
				color: $dropdown-menu-item-color;
				line-height: 30px;
				> span[role='img'] {
					color: $default-color;
				}
			}

			.ant-dropdown-menu-item-selected,
			.ant-dropdown-menu-item:hover,
			.ant-dropdown-menu-submenu-title:hover {
				background-color: $dropdown-menu-item-hover-bg;
			}
		}
	}

	:global {
		.ant-dropdown-menu {
			> .ant-dropdown-menu-item {
				display: flex;
				span[role='img'] {
					align-self: center;
					margin-right: 7px;
				}
			}
		}
	}
}
