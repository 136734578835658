@import 'Styles/_theme.scss';

.groupTable {
	:global {
		tbody > .table-data {
			background-color: #fafafa;
		}

		tbody > .form-control > td {
			background-color: $white;
		}
		.ant-table-header {
			table > thead > tr {
				pointer-events: none;
			}
		}
	}
}

.levelTitle {
	font-size: $heading-3;
}
