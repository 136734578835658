@import 'Styles/_theme.scss';

.root {
	color: $white;
	.logo {
		svg {
			display: block;
		}
	}

	a + .headerMenu {
		margin-left: 45px;
	}

	$profile-item-gap: 25px;
	.profile {
		> * {
			&:not(:last-child) {
				margin-right: $profile-item-gap;
			}

			> span[role='img'] {
				display: inline;
			}
		}

		.profileName {
			line-height: 28px;
			border-left: 1px solid $border-color;
			padding-left: $profile-item-gap !important;
		}
	}
}

.headerMenuContainer {
	flex: 1 1 auto; // menu isnt expanded after shrinking in width
	margin-left: $gap-sm * 2;
}

.headerMenu {
	height: 100%;
	background: transparent;
	border-bottom: none;
	line-height: $app-header-height !important;

	> li {
		&:global {
			&.ant-menu-item,
			&.ant-menu-submenu {
				color: $white;
				border-bottom: 0 !important;
			}

			&.ant-menu-item:hover,
			&.ant-menu-submenu:hover,
			&.ant-menu-item-active,
			&.ant-menu-submenu-active,
			&.ant-menu-item-open,
			&.ant-menu-submenu-open,
			&.ant-menu-item-selected,
			&.ant-menu-submenu-selected {
				font-weight: 600;
				color: $main-color !important;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
					background-color: $main-color;
				}
			}

			&.ant-menu-item-disabled {
				&.ant-menu-item-only-child {
					color: $white !important;
					&:hover {
						color: $main-color !important;
					}
				}
			}

			&.ant-menu-item,
			&.ant-menu-submenu > .ant-menu-submenu-title {
				padding-right: $gap !important;
				padding-left: $gap !important;
			}

			&.ant-menu-submenu > .ant-menu-submenu-title {
				&:hover {
					color: $main-color !important;
				}
			}

			&.ant-menu-item,
			&.ant-menu-submenu {
				margin-top: 0px !important;
				top: 0px !important;
				margin: 0 !important;
				font-size: $font-size-md;
				&:not(:last-child) {
					margin-right: $gap-sm !important;
				}
			}
		}
	}
}

.headerSubMenu {
	z-index: $dropdown-z-index;
	:global {
		.ant-menu {
			margin-top: -7px;
			min-width: $dropdown-min-width;
			> .ant-menu-item {
				color: $dropdown-menu-item-color;
				margin-top: 0;
				margin-bottom: 0;
				&:hover {
					background-color: $dropdown-menu-item-hover-bg;
				}
			}
		}
	}
}

.headerSubMenuItem {
	span[role='img'] {
		display: none;
	}

	.headerMenu & {
		> :global(.ant-menu-submenu-title) {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
		}
		span[role='img'] {
			display: block !important;
			margin-left: $gap;
		}
	}
}
