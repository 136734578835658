@import 'Styles/_theme.scss';

.icons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.iconBox {
	width: 120px;
	height: 120px;
	text-align: center;
	background: $blue-3;
	border: 1px solid $border-color;
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.icon {
	width: 30px;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;

	span,
	svg {
		width: 100%;
		height: 100%;
		display: block;
	}
}
