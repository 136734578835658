@import 'Styles/_theme.scss';

.root {
	display: flex;

	&.column {
		flex-direction: column;
	}

	// Explicitly setting the min height to zero, or else the content may overflow its parent
	min-height: 0;
	//By default, flex items won\'t shrink below their minimum content size
	//(the length of the longest word or fixed-size element).
	//‘min-width’ is set to change this
	> * {
		min-width: 0;
	}
}

.inline {
	display: inline-flex;
}

.fullHeight {
	height: 100%;
}

.fullWidth {
	width: 100%;
}

.wrap {
	flex-wrap: wrap;
}

.top {
	align-items: flex-start;
}

.middle {
	align-items: center;
}

.bottom {
	align-items: flex-end;
}

.stretch {
	align-items: stretch;
}

.center {
	justify-content: center;
}

.end {
	justify-content: flex-end;
}

.between {
	justify-content: space-between;
}

.around {
	justify-content: space-around;
}

.even {
	justify-content: space-evenly;
}

.selfBottom {
	align-self: flex-end;
}
