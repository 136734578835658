@import 'Styles/_theme.scss';

@keyframes indeterminateAnimation {
	0% {
		transform: translateX(0) scaleX(0);
	}

	40% {
		transform: translateX(0) scaleX(0.4);
	}

	100% {
		transform: translateX(100%) scaleX(0.5);
	}
}

.progress {
	:global(.ant-progress-bg) {
		animation: indeterminateAnimation 2s infinite linear;
		width: 100%;
		transform-origin: 0% 50%;
		transition: none;
	}
}
