@import 'Styles/_theme.scss';

.root {
	margin: $gap 0;
}

.icon {
	vertical-align: middle;
	margin-left: $gap;
	font-size: $font-size-sm;
}
