@import 'Styles/_theme.scss';

.root {
	:global {
		.ant-select-arrow {
			margin-top: -$gap;
			right: $gap-xs;
			color: $input-icon-color;
		}
		.ant-select-clear {
			right: $gap-xs;
			font-size: $input-clear-icon-size;
			color: $input-icon-color;
			margin-top: -$gap;
		}
	}
	&:global {
		// hide current value if field is loading and value isn't mapped
		&.ant-select-loading .ant-select-selection-item {
			visibility: hidden;
		}

		&.ant-select-disabled {
			pointer-events: none;
		}
	}

	&:global(.ant-select-multiple) {
		:global {
			.ant-select-selection-item {
				background-color: $gray-2;
				border-radius: $tag-border-radius;
			}
			.ant-select-selection-item-remove {
				font-size: 17px;
				margin: 2px 5px 0 5px;
			}
			.ant-select-selection-item-content {
				line-height: 1.65;
			}
		}
	}
}

.dropdown {
	box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
		0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
	padding: 0;
	border-radius: 0;
	z-index: $dropdown-z-index;

	:global {
		.ant-select-item {
			padding: 8px 16px;
			line-height: 24px;
		}

		.ant-select-item-option-active {
			background-color: $dropdown-menu-item-hover-bg;
		}
	}
}
