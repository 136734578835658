@import 'Styles/_theme.scss';
@import 'Styles/_mixins.scss';

.root {
	@extend %scrollable;
	@extend %thead;
	tbody > tr > td {
		padding-top: $table-cell-vertical-padd;
		padding-bottom: $table-cell-vertical-padd;
		border-top: 1px solid transparent;
		border-bottom: 1px solid $border-color;
		line-height: 1.4;
	}

	:global {
		.ant-table-body {
			border: 1px solid $border-color;
			border-top: 0;
			overflow-y: auto !important;
		}

		.ant-table-empty {
			.ant-table-body > table {
				height: 100%;
				.ant-table-placeholder > td {
					background-color: $body-background;
				}
			}
		}

		.ant-table-filter-trigger {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $font-size-md;

			&:not(.active) {
				color: $default-color;
			}
		}

		.ant-table-column-sorters {
			width: 100%;
			justify-content: space-between;
		}
	}
}

%scrollable {
	overflow-y: auto;
	height: 100%;
	// fixing bug with jumping height at Safari 14.1
	min-height: calc(100% - 129px);
	:global {
		.ant-spin-nested-loading,
		.ant-spin-container,
		.ant-table,
		.ant-table-container {
			height: 100%;
		}

		.ant-table-container {
			display: flex;
			flex-direction: column;
		}

		.ant-table-header {
			flex-shrink: 0;
			overflow: hidden !important;

			thead > tr > th:first-child {
				&.ant-table-selection-column {
					padding: 0;
				}
			}
		}

		.ant-table-body {
			flex: 1;
			overflow-y: auto;
		}
	}
}

.emptyText {
	margin: 0 auto;
	max-width: 400px;
	height: 100%;
	padding-top: 110px;

	h1 + h3 {
		margin-top: $gap-sm;
	}
}

.totalItems {
	padding: $gap 0;
}
