@import 'Styles/_theme.scss';

.root {
	width: 100% !important;
	max-width: 100%;
	height: 100%;
	top: 0;
	margin: 0;
	left: 0;
	right: 0;
	padding: 0;
	:global {
		.ant-modal-content {
			height: 100%;
			overflow-y: hidden;
			border-radius: 0;
			background-color: $body-background;
			display: flex;
			flex-direction: column;
			.ant-modal-header {
				background-color: inherit;
			}
		}

		.ant-modal-body {
			flex: 1;
			height: 100%;
			max-height: 90%;
			padding-bottom: 20px;
		}

		.ant-modal-title {
			text-align: left;
			font-weight: 300;
			line-height: 32px;
		}
	}
}
