@import 'Styles/_theme.scss';

.title {
	color: $blue-2;
	margin-bottom: calc(#{$gap-xs} * 2);
}

.layout {
	min-height: 100%;
	overflow-y: auto;
	position: relative;
}

.content {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}

.icon {
	margin-top: 99px;
	margin-bottom: $gap-xs;
}

.description {
	max-width: 400px;
	font-size: 18px;
	text-align: center;

	+ button {
		margin-top: 56px;
	}
}
