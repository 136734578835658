@import 'Styles/_theme.scss';

.root {
	@each $type-name, $type in $progress-types {
		&.#{$type-name} {
			:global {
				.ant-progress-circle-path {
					stroke: map-get($type, 'mainColor') !important;
				}
			}
		}
	}

	:global {
		.ant-progress-text {
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-top: 34px;
			position: static;
			transform: none;
		}
		.ant-progress-inner {
			height: auto !important;
			border-radius: 0;
		}
		.ant-progress-circle {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&.large {
		:global {
			.ant-progress-text {
				margin-top: 50px;
			}
		}
	}
}

.root {
	:global(.ant-progress-inner) {
		overflow: visible;
		.label {
			white-space: nowrap;
		}
	}
}
