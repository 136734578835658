@import 'Styles/_theme.scss';

.subTitle {
	font-size: 10px;
	color: $gray-1;
}

.button {
	padding-left: 0px !important;
}
