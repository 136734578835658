@import 'Styles/_theme.scss';

.tabs {
	margin: 0 (-$app-padd-horizontal);
	> [role='tablist'] {
		padding: 0 $app-padd-horizontal;
	}

	:global {
		.ant-tabs-content-holder {
			background-color: transparent;
			border: 0;
			padding: $gap-sm $app-padd-horizontal;
		}
	}
}

.filters {
	margin-bottom: $gap;
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-select {
			width: 180px;
		}
		.ant-select-selector {
			max-height: 40px;
			overflow: auto;
		}
	}

	.suffixField {
		&::before {
			content: '';
			display: block;
			height: inherit;
			background-color: $border-color;
			width: 1px;
			margin: 0 $gap-xs;
		}
	}
}
