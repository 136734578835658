@import 'Styles/_theme.scss';

.root {
	:global(.ant-table) & {
		vertical-align: top;
		margin-right: 4px;

		:global {
			svg {
				width: 16px;
				height: 16px;
			}
		}
	}

	@each $type-name, $type in $progress-types {
		&.#{$type-name} {
			color: map-get($type, 'mainColor');
		}
	}
}
