@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import './font';
@import './theme';
@import './mixins';

html,
body,
#root {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Titillium', sans-serif !important;
	background-color: $body-background !important;
	color: $text-color;
	font-size: $font-size-sm;
}

a {
	color: $link-color !important;
	font-weight: 300;

	&:hover,
	&:focus,
	&:active {
		text-transform: underline;
	}
}

hr {
	height: 2px !important;
	background-image: linear-gradient(
		to right,
		$border-color 0%,
		$border-color 50%,
		transparent 50%
	);
	background-size: 15px 2px;
	background-repeat: repeat-x;
	border: none;
	margin-top: $gap-md;
	margin-bottom: $gap-md;
}

body {
	h1,
	h2,
	h3,
	h4 {
		color: $text-color;
		margin-bottom: 0;
	}
	h1,
	h2,
	h3 {
		font-weight: 300;
	}

	h4 {
		font-weight: 600;
	}
}

h1 {
	font-size: $heading-1;
	line-height: 32px;
}

h2 {
	font-size: $heading-2;
}

h3 {
	font-size: $heading-3;
}

h4 {
	font-size: $heading-4;
}

.buttoncnthalf{
	width:50%;
	padding:0px;
	margin:0px;
	text-align:center;
	display:inline-block;
}
.divtitlecenter{
	width:100%;
	font-size:2vw;
	font-weight:bold;
}
.divfullwidthcenter{
	width:100%;
	text-align:center;
}
.div75widthcenter{
	width:75%;
	text-align:center;
	display:inline-block;
}