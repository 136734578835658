@import 'Styles/_theme.scss';

%icon {
	position: absolute;
	left: 0;
	margin-left: $gap-xs;
	color: $input-icon-color;
	top: 0;
	margin-top: 0;
	height: 100%;
	display: inline-flex;
	align-items: center;
}

.select {
	:global {
		.ant-select-selector {
			padding-left: $picker-prefix-gap !important;
		}
		.ant-select-arrow {
			@extend %icon;
			*[role='img'] {
				display: inline-flex;
			}
		}
	}
}

.input {
	padding-left: $picker-prefix-gap;

	:global {
		.ant-input-prefix {
			@extend %icon;
			left: -1px;
		}
	}
}
